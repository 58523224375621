import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Anchor } from '../components/Anchor';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1, Typography } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const Paragraph = styled(Typography)`
  font-size: 12pt;
  line-height: 1.75;
  margin-bottom: 1rem;
`;

const About: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="About" />
      <Container>
        <H1 className="mb-6" color="#051392">
          About HyperCite<sup>&reg;</sup>
        </H1>

        <Paragraph>
          HyperCite<sup>&reg;</sup>, Inc. is a California corporation focused on delivering
          hyperlinking, filing, document &amp; case management, and artificial intelligence
          solutions to courts and attorneys nationwide. We built HyperCite<sup>&reg;</sup> to
          automate away the tedium of hyperlinking citations. This version of HyperCite
          <sup>&reg;</sup> is specific to Arizona Court of Appeals, Division Two filers. It
          integrates seamlessly with the Court’s document management system. For media inquiries, or
          to find out how HyperCite
          <sup>&reg;</sup>, Inc. could help your own court, please address correspondence to{' '}
          <Anchor href="mailto:dennis@hypercite.net">dennis@hypercite.net</Anchor>.
        </Paragraph>
      </Container>
    </PublicRoute>
  );
};

export default About;
